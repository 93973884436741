import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoginStatus: false,
    accountStore: "",
    clickedTimes: 0,
  },
  getters: {
    getAccountLenght(state) {
      return state.accountStore.length;
    },
  },
  mutations: {
    updateIsLoginStatus(state, value) {
      state.isLoginStatus = value;
    },

    updateAccount(state, accountStore) {
      state.accountStore = accountStore;
      // console.log(state.accountStore);
    },

    addTimes(state, payload) {
      // console.log("mu", state, payload.count);
      // console.log(typeof payload);
      state.clickedTimes = state.clickedTimes + payload.count;
    },

    getData(state) {
      state.clickedTimes =
        JSON.parse(localStorage.getItem("saveWoodenFish")) ||
        state.clickedTimes;
    },
  },
  actions: {
    login({ state, commit }) {
      //將登入狀態改為 是
      commit("updateIsLoginStatus", true);
      // state.isLoginStatus = true;
      console.log("commit", commit);
      console.log("登入狀態", state.isLoginStatus);
      console.log("帳號", state.accountStore);
    },
    signOut({ state, commit }) {
      //將登入狀態改為 否
      // state.isLoginStatus = false;
      commit("updateIsLoginStatus", false);
      console.log("登入狀態", state.isLoginStatus);
    },
    ClickedActions({ commit }, payload) {
      // console.log(typeof payload);
      commit("addTimes", payload);
    },
    getSaveData({ commit }) {
      commit("getData");
    },
  },
  modules: {},
});
