<template>
  <div class="about_page">
    <div class="banner">
      <div class="txt_box">
        World’s biggest and most popular betting and gaming brands!
        <div class="btn_join" @click="$router.push('/Join')">Join Us</div>
      </div>
    </div>

    <section class="section_who">
      <h3>Who We Are</h3>
      <div class="txt txt1">
        K8 Group is the parent company for some of the world's leading online gaming and crypto currency casino brands,
        with operations in more than 4 regulated markets and a global presence.
      </div>
      <div class="txt txt2">
        For K8, it's all about bringing entertainment to life for millions of users in a safe, responsible and
        sustainable way.
      </div>
      <div class="txt txt3">
        Although we have the scale of a leader, our challenger mindset is what allows us to constantly explore new
        spaces, markets and opportunities to engage and entertain our users.
      </div>
    </section>

    <section class="section_culture">
      <div class="left_box">
        <img class="pc" src="@/assets/images/about/culture1.jpg" alt="" />
        <img class="h5" src="@/assets/images/about/culture1_h5.jpg" alt="" />
      </div>
      <div class="right_box">
        <h3>Our Culture</h3>
        <img class="pc" src="@/assets/images/about/culture2.jpg" alt="" />
        <img class="h5" src="@/assets/images/about/culture2_h5.jpg" alt="" />
      </div>
      <div class="txt_box">
        Plus a fun, friendly and entrepreneur environment. K8 built a culture based on trust, accountability and the
        pursuit of improvement. This leads to self driven employees which is the key to continue growing fast.
      </div>
    </section>

    <section class="section_number number1">
      <div class="item_box top">
        <div class="item">
          <img src="@/assets/images/about/block1.png" alt="" />
          <div class="txt_box">
            <span class="num">07</span>
            <span class="tip">Years Growth</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block2.png" alt="" />
          <div class="txt_box">
            <span class="num">500+</span>
            <span class="tip">Employees</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block3.png" alt="" />
          <div class="txt_box">
            <span class="num">006</span>
            <span class="tip">Nationalities</span>
          </div>
        </div>
      </div>
      <div class="item_box bottom">
        <div class="item">
          <img src="@/assets/images/about/block4.png" alt="" />
          <div class="txt_box">
            <span class="num">32</span>
            <span class="tip">Years The Average Age</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block5.png" alt="" />
          <div class="txt_box">
            <span class="num">007</span>
            <span class="tip">Global Offices</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section_vision pc">
      <div class="vision_box top">
        <div class="txt_box">
          <h4>OUR VISION</h4>
          <div class="txt top">
            Bringing people closer to the game, embracing the newest technologies and offering our users new
            experiences.
          </div>
          <div class="txt">
            We wanted to convey strong emotions to our users by placing them at the centre of our priorities, driven by
            their love of gambling.
          </div>
        </div>
        <img class="pic" src="@/assets/images/about/version2.jpg" alt="" />
      </div>
      <div class="vision_box">
        <img class="pic bottom" src="@/assets/images/about/version1.jpg" alt="" />
        <div class="txt_box">
          <h4>OUR MISSION</h4>
          <div class="txt">
            To enhance the user experience to increase our user’s love of gaming and exceed their expectations every
            day.
          </div>
        </div>
      </div>
    </section>

    <section class="section_vision h5">
      <div class="txt_box top">
        <h4>OUR VISION</h4>
        <div class="txt">
          Bringing people closer to the game, embracing the newest technologies and offering our customers new
          experiences.
        </div>
      </div>
      <div class="img_box">
        <img class="pic" src="@/assets/images/about/version2.jpg" alt="" />
        <img class="pic" src="@/assets/images/about/version1.jpg" alt="" />
      </div>
      <div class="txt_box">
        <h4>OUR MISSION</h4>
        <div class="txt">
          To enhance the user experience to increase our user’s love of gaming and exceed their expectations every day.
        </div>
      </div>
    </section>

    <section class="section_number number2">
      <div class="item_box top">
        <div class="item">
          <img src="@/assets/images/about/block6.png" alt="" />
          <div class="txt_box">
            <span class="num small">100k+</span>
            <span class="tip">Bet Placed per day</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block7.png" alt="" />
          <div class="txt_box">
            <span class="num">400+</span>
            <span class="tip">Casino Games</span>
          </div>
        </div>
        <div class="item h5">
          <img src="@/assets/images/about/block8.png" alt="" />
          <div class="txt_box">
            <span class="num">3M+</span>
            <span class="tip">Players</span>
          </div>
        </div>
      </div>
      <div class="item_box bottom">
        <div class="item pc">
          <img src="@/assets/images/about/block8.png" alt="" />
          <div class="txt_box">
            <span class="num">3M+</span>
            <span class="tip">Players</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block9.png" alt="" />
          <div class="txt_box">
            <span class="num">180+</span>
            <span class="tip">Servers 24/7 support in 4 languages</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/about/block10.png" alt="" />
          <div class="txt_box">
            <span class="num">20+</span>
            <span class="tip">Products + 1 crypto Currency</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section_map">
      <div class="content">
        <h4>K8’s Location</h4>
        <div class="info">
          K8's headquarter is based in the CBD of Manila, the Philippines, and 7 branch offices in countries worldwide:
        </div>
        <div class="map_box">
          <img class="map_bg" src="@/assets/images/about/map.png" alt="" />
          <div class="location_item l1">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Dubai</span>
          </div>
          <div class="location_item l2">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Osaka</span>
          </div>
          <div class="location_item l3">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Taipei</span>
          </div>
          <div class="location_item l4">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Hongkong</span>
          </div>
          <div class="location_item l5">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Ho Chi Minh</span>
          </div>
          <div class="location_item l6">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Singapore</span>
          </div>
          <div class="location_item l7">
            <img class="icon_location" src="@/assets/images/about/icon_location.png" alt="" />
            <span class="location_name">Manila</span>
          </div>
        </div>
        <div class="stop_list">
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Manila</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Dubai</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Singapore</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Ho Chi Minh</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Osaka</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Taipei</span>
          </div>
          <div class="stop_item">
            <div class="icon_box">
              <img class="icon_location2" src="@/assets/images/about/icon_location2.png" alt="" />
            </div>
            <span class="stop_txt">Hongkong</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section_growth">
      <h4>A 7-YEAR GROWTH</h4>
      <div class="info">
        K8's headquarter based in the CBD of Manila, the Philippines, and 7 branch offices in countries worldwide:
      </div>
      <div class="tree_box">
        <div class="item_box">
          <img class="pic_tree pc" src="@/assets/images/about/tree.png" alt="" />
          <img class="pic_tree h5" src="@/assets/images/about/tree_h5.png" alt="" />
        </div>
      </div>
    </section>

    <section class="section_people">
      <div class="content">
        <div class="txt_box">
          <img class="icon_dot" src="@/assets/images/about/icon_dotdot.png" alt="" />
          <div class="txt">
            “Thanks to our valuable users, K8 has been flourishing year by year. We valued your enthusiasm for gaming
            and affirmation of our services. We will continue to bring you a perfect gaming experience and innovative
            products for you and we achieve a better future together.”
          </div>
        </div>
        <div class="people_box">
          <img class="pic_tree" src="@/assets/images/about/people.png" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'aboutPage',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
// * {
//   outline: 1px solid #ccc;
// }

.about_page {
  img {
    width: 100%;
  }
  .banner {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: url('~@/assets/images/about/banner.jpg') no-repeat center / 100% 100%;

    .txt_box {
      position: absolute;
      top: 40%;
      left: 14%;
      color: #fff;
      font-size: 50px;
      font-weight: 700;
      text-align: left;
      line-height: 1.5;
      width: 830px;

      .btn_join {
        width: 174px;
        height: 48px;
        border: 1px solid #f31299;
        border-radius: 24px;
        text-align: center;
        line-height: 48px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        color: #f31299;
        margin-top: 36px;
      }
    }
  }

  .section_who {
    // padding-top: 79px;
    // padding-bottom: 217px;
    padding-top: 4.1%;
    padding-bottom: 11%;
    width: 1118px;
    margin: auto;
    text-align: center;

    h3 {
      font-size: 40px;
      font-weight: 700;
      line-height: 67px;
      text-align: center;
      margin-bottom: 28px;
    }

    .txt {
      font-size: 18px;
      font-weight: 400;
      color: #1c1f28;
      line-height: 1.5;
      letter-spacing: 0.5px;

      &.txt2 {
        padding: 30px 0;
      }
    }
  }

  .section_culture {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 1374px;
    margin: auto;
    margin-bottom: 23%;
    padding-bottom: 5%;

    .left_box {
      width: 612px;
      margin-right: 5%;
    }

    .right_box {
      width: 693px;

      h3 {
        font-weight: 700;
        font-size: 40px;
        color: #1c1f28;
        margin: 20px 0 50px;
        text-align: left;
      }
    }

    .txt_box {
      position: absolute;
      width: 438px;
      font-size: 18px;
      font-weight: 400;
      padding: 36px;
      height: fit-content;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      background-color: #6300bf;
      color: #fff;
      line-height: 1.5;
      box-sizing: border-box;
    }
  }

  .section_number {
    max-width: 1374px;
    margin: auto;
    box-sizing: border-box;

    &.number1 {
      margin-bottom: 23%;
    }

    &.number2 {
      margin-bottom: 15%;
    }

    .item_box {
      display: flex;
      width: 100%;
      justify-content: flex-start;

      &.bottom {
        justify-content: flex-end;
        margin-top: 5.7%;

        .item {
          margin-right: 0;
          margin-left: 3%;
        }
      }

      .item {
        width: 20%;
        position: relative;
        color: #fff;
        margin-right: 3%;

        .txt_box {
          position: absolute;
          left: 10%;
          bottom: 10%;
          text-align: left;
          width: fit-content;
          height: fit-content;

          .num {
            font-size: 90px;
            font-weight: 600;
            display: inline-block;
            line-height: 1.3;

            &.small {
              font-size: 80px;
            }

            // width: 100%;
          }

          .tip {
            font-size: 20px;
            font-weight: 400;
            display: inline-block;
            width: 100%;
          }
        }
      }
    }
  }

  .section_vision.pc {
    max-width: 1374px;
    margin: auto;
    margin-bottom: 18.9%;
    box-sizing: border-box;
    padding-right: 1%;
    background: url('~@/assets/images/about/version_bg.png') no-repeat center center / 100%;

    .vision_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.top {
        margin-bottom: 5.1%;
      }

      .txt_box {
        width: 41.6%;
        height: fit-content;
        color: #1c1f28;
        text-align: left;
        line-height: 1.5;

        h4 {
          font-size: 40px;
          font-weight: 700;
        }

        .txt {
          font-size: 18px;
          font-weight: 400;

          &.top {
            margin-bottom: 5%;
          }
        }
      }

      .pic {
        width: 52.2%;

        &.bottom {
          width: 41.6%;
        }
      }
    }
  }

  .section_map {
    margin-bottom: 13.4%;
    box-sizing: border-box;
    color: #1c1f28;
    text-align: center;
    background-color: #f4f5f9;
    .content {
      max-width: 1374px;
      margin: auto;
      padding: 50px 0px;
    }
    h4 {
      font-weight: 700;
      font-size: 40px;
      margin-bottom: 16px;
    }

    .info {
      font-weight: 400;
      font-size: 18px;
      width: 45%;
      margin: auto;
      margin-bottom: 4.1%;
    }

    .map_box {
      width: 86.9%;
      margin: auto;
      position: relative;

      .location_item {
        width: 2.5%;
        position: absolute;

        &.l1 {
          top: 49%;
          left: 22%;
        }

        &.l2 {
          top: 30%;
          left: 79%;
        }

        &.l3 {
          top: 47.3%;
          left: 70.5%;
        }

        &.l4 {
          top: 51.4%;
          left: 65.5%;
        }

        &.l5 {
          top: 64%;
          left: 62%;
        }

        &.l6 {
          top: 80.2%;
          left: 58.6%;
        }

        &.l7 {
          top: 62.9%;
          left: 71.2%;
        }

        .icon_location {
          width: 100%;
        }

        .location_name {
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          white-space: nowrap;
          display: none;
        }
      }
    }

    .stop_list {
      width: 91%;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .stop_item {
        width: 110px;
        text-align: center;

        .icon_box {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          background: linear-gradient(0deg, #f31299 0%, #6300bf 100%);
          position: relative;
          margin: auto;
          margin-bottom: 25px;

          .icon_location2 {
            width: 13px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
          }
        }

        .stop_txt {
          color: #000;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .section_growth {
    max-width: 1374px;
    margin: auto;
    margin-bottom: 13.4%;
    box-sizing: border-box;
    color: #1c1f28;
    line-height: 1.5;

    h4 {
      font-weight: 700;
      font-size: 40px;
      color: #1c1f28;
      text-align: center;
      margin-bottom: 1%;
    }

    .info {
      font-weight: 400;
      font-size: 18px;
      color: #000;
      width: 50%;
      margin: auto;
      text-align: center;
      margin-bottom: 6.8%;
    }

    .tree_box {
      width: 58%;
      margin: auto;
    }
  }

  .section_people {
    background: url('~@/assets/images/about/people_bg.png') no-repeat center / 100% 100%;
    margin-bottom: 13.4%;
    .content {
      max-width: 1374px;
      margin: auto;
      box-sizing: border-box;
      line-height: 1.5;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .txt_box {
      width: 43.7%;
      order: 2;

      .icon_dot {
        width: 70px;
        margin-bottom: 6.8%;
      }
      .txt {
        font-size: 18px;
        color: #1c1f28;
      }
    }

    .people_box {
      width: 43.3%;
      order: 3;
    }
  }

  // H5版
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .banner {
      background: url('~@/assets/images/about/banner_h5.jpg') no-repeat center / 100% 100%;
      padding-bottom: 162.1%;
      order: 1;

      .txt_box {
        width: 80%;
        left: 0;
        right: 0;
        margin: auto;
        top: 27%;
        font-size: 25px;
        font-weight: 700;
        text-align: center;

        .btn_join {
          margin: auto;
          margin-top: 90%;
        }
      }
    }

    .section_who {
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;
      margin-bottom: 18%;
      order: 2;

      h3 {
        font-size: 20px;
        margin-bottom: 8px;
      }

      .txt {
        font-size: 14px;

        &.txt2 {
          padding: 15px 0;
        }

        &.txt3 {
          display: none;
        }
      }
    }

    .section_culture {
      width: 100%;
      padding-top: 13.4%;
      padding-bottom: 0;
      flex-wrap: wrap;
      margin-bottom: 14.4%;
      order: 4;

      .left_box {
        margin-right: 0;
        width: 47.9vw;
      }

      .right_box {
        width: 47.9vw;
        padding-top: 30%;

        h3 {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          text-align: center;
          font-size: 20px;
        }
      }

      .txt_box {
        position: relative;
        width: 80%;
        font-size: 14px;
        padding: 26px 21px;
        margin-top: 5vw;
      }
    }

    .section_number {
      width: 100%;
      padding: 0 4%;

      &.number1 {
        margin-bottom: 26%;
        order: 3;
      }

      &.number2 {
        margin-bottom: 30%;
        order: 5;
      }

      .item_box {
        justify-content: center;

        &.bottom {
          justify-content: center;
          margin-top: 5.7%;

          .item {
            margin-right: 0;
            margin-left: 3%;
          }
        }

        .item {
          width: 30%;

          .txt_box {
            width: 86%;
            .num {
              font-size: 32px;

              &.small {
                font-size: 30px;
              }
            }

            .tip {
              font-size: 12px;
            }
          }
        }
      }
    }

    .section_vision.h5 {
      width: 100%;
      margin-bottom: 30.9%;
      background: url('~@/assets/images/about/version_bg.png') no-repeat center 20%/106%;
      order: 6;

      .txt_box {
        width: 70%;
        margin: auto;
        text-align: center;

        &.top {
          margin-bottom: 21.3%;
        }

        h4 {
          font-weight: 700;
          font-size: 20px;
          color: #000;
        }

        .txt {
          font-weight: 400;
          font-size: 14px;
          color: #1c1f28;
        }
      }

      .img_box {
        display: flex;
        margin-bottom: 34%;

        .pic {
          width: 50%;
        }
      }
    }

    .section_map {
      order: 7;
      h4 {
        font-size: 21px;
      }

      .info {
        font-size: 14px;
        width: 76%;
        margin-bottom: 9%;
      }

      .map_box {
        width: 100%;
        overflow: hidden;

        .map_bg {
          width: 138%;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }

        .location_item {
          &.l1 {
            top: 50%;
            left: 11.5%;
          }

          &.l2 {
            top: 31%;
            left: 90.4%;
          }

          &.l3 {
            top: 48.2%;
            left: 78.4%;
          }

          &.l4 {
            top: 51.4%;
            left: 72%;
          }

          &.l5 {
            top: 64%;
            left: 66%;
          }

          &.l6 {
            top: 80.2%;
            left: 61.2%;
          }

          &.l7 {
            top: 62.9%;
            left: 78.9%;
          }

          .location_name {
            display: block;
            font-size: 8px;
          }
        }
      }

      .stop_list {
        display: none;
      }
    }

    .section_growth {
      width: 100%;
      order: 8;

      h4 {
        font-size: 20px;
      }

      .info {
        font-size: 14px;
        width: 75.8%;
      }

      .tree_box {
        width: 100%;
      }
    }
    .section_people {
      order: 9;
      .content {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .txt_box {
        width: 100%;
        box-sizing: border-box;
        padding: 0 5%;
        .icon_dot {
          width: 13.3%;
        }
        .txt {
          font-size: 14px;
        }
      }
      .people_box {
        width: 51.3%;
        order: 1;
        padding-right: 8%;
      }
    }
  }
}
</style>
