<template>
  <div id="app">
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
    <ScrollTopBtn />
  </div>
</template>
<script>
export default {
  components: {
      ScrollTopBtn: () => import('@/components/ScrollTopBtn.vue'),
    },
  data () {
    return {
    }
  },
  methods: {
  }
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // padding: 0;
  // margin: 0;
  max-width: 1920px;
  min-width: 1200px;
  margin: auto;
  img{
    -webkit-user-drag: none
  }
  .h5 {
    display: none;
  }

  @media screen and (max-width: 768px) {
    min-width: initial;
    .pc {
      display: none;
    }
    .h5 {
      display: block;
    }
  }
}

* {
  padding: 0;
  margin: 0;
}
img {
  // width: 100%;
  vertical-align: bottom;
}
</style>
