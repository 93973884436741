<template>
  <div class="footer">
    <div class="footer_detail">
      <div class="footer_info">
        <div class="footer_logo">
          <img src="@/assets/images/common/logo.png" />
        </div>
        <p>
          The purpose of this website is solely to display information regarding the products and services provided by
          K8 Group.
        </p>
        <div class="footer_icon_box pc">
          <img class="footer_icon" src="@/assets/images/footer/icon_facebook.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_twitter.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_linkedin.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_mail.png" />
          <span>branding@k8vn.com</span>
        </div>
        <div class="footer_icon_box h5">
          <img class="footer_icon" src="@/assets/images/footer/icon_facebook_h5.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_twitter_h5.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_linkedin_h5.png" />
          <img class="footer_icon" src="@/assets/images/footer/icon_mail_h5.png" />
          <span>branding@k8vn.com</span>
        </div>
      </div>
      <div class="footer_content">
        <h2>Content</h2>
        <div class="nav">
          <span>Content</span>
          <span @click="toPage('About')">About Us</span>
          <!-- <span @click="toPage('News')">News</span> -->
          <span @click="toPage('Sustainability')">Sustainability</span>
          <span @click="toPage('Join')">Join us</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="footer_copyright">
      <div class="copyright">COPYRIGHT © 2022 k8 Group All Rights Reserved</div>
      <div class="footer_policy">
        <span>Terms of use</span> |
        <span>Privacy Policy</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MainHeader',
    data() {
      return {}
    },
    methods: {
      toPage(url) {
        this.$router.push(url)
        this.scrollTop()
      },
      scrollTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  * {
    // outline: 1px #ccc solid;
  }
  .footer {
    max-width: 1374px;
    min-width: 1200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 0px 91px 0px;
  }
  .footer .footer_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .footer_info p {
    padding-top: 30px;
    font-size: 12px;
    color: #161a21;
    font-weight: 400;
    line-height: 24px;
    width: 372px;
    text-align: left;
  }
  .footer .footer_info .footer_logo {
    width: 103px;
  }
  .footer .footer_info img {
    width: 100%;
    vertical-align: bottom;
  }
  .footer .footer_detail .footer_info .footer_icon_box {
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 23px;
  }
  .footer .footer_info .footer_icon_box .footer_icon {
    width: 31px;
    margin-right: 16px;
    // cursor: pointer;
    // &:hover{
    //   opacity: 0.5;
    // }
  }
  .footer .footer_content h2 {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    padding-bottom: 27px;
  }
  .footer .footer_content .nav {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }
  .footer .footer_content .nav span {
    padding-bottom: 16px;
    cursor: pointer;
    &:first-child {
      display: none;
    }
  }
  .footer .footer_content .nav span:hover {
    opacity: 0.5;
  }
  .footer .nav {
    display: flex;
    flex-direction: column;
  }
  .footer .line {
    background-color: #000;
    height: 1px;
    width: 100%;
  }
  .footer .footer_copyright {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .footer .footer_copyright .copyright {
    color: #161a21;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }
  .footer .footer_copyright .footer_policy {
    color: #161a21;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }
  .footer .footer_copyright .footer_policy span {
    padding-left: 23px;
    // cursor: pointer;
    // &:hover{
    //   opacity: 0.5;
    // }
  }

  .footer .footer_copyright .footer_policy span:first-child {
    padding: 0px 23px;
  }
  @media screen and (max-width: 768px) {
    .footer {
      min-width: 100%;
      box-sizing: border-box;
      padding: 44px 33px;
    }
    .footer .footer_detail {
      flex-direction: column;
    }
    .footer .footer_info p {
      padding-top: 10px;
      width: 100%;
      text-align: left;
      color: #9294ad;
      line-height: 18px;
      // letter-spacing: 1px;
      line-height: 24px;
    }
    .footer .footer_info .footer_logo {
      width: 53px;
    }

    .footer .footer_detail .footer_info .footer_icon_box {
      display: flex;
      align-items: center;
      padding-top: 14px;
      padding-bottom: 23px;
      flex-wrap: wrap;
      span {
        color: #9294ad;
        line-height: 24px;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .footer .footer_info .footer_icon_box .footer_icon {
      width: 24px;
      margin-right: 12px;
      // cursor: pointer;
      // &:hover{
      //   opacity: 0.5;
      // }
    }
    .footer_content {
      text-align: left;
    }
    .footer .footer_content h2 {
      font-size: 14px;
      line-height: 0px;
      font-weight: 400;
      padding-bottom: 0px;
      color: #9294ad;
      display: none;
    }
    .footer .footer_content .nav {
      display: flex;
      align-content: center;
      align-items: flex-start;
      margin-bottom: 25%;
    }
    .footer .footer_content .nav span {
      padding-bottom: 16px;
      color: #9294ad;
      cursor: pointer;
      letter-spacing: 0.5px;
      &:first-child {
        cursor: default;
        display: block;
      }
    }

    .footer .footer_content .nav span:hover {
      opacity: 0.5;
      &:first-child {
        opacity: 1;
      }
    }
    .footer .nav {
      display: flex;
      flex-direction: column;
    }
    .footer .line {
      background-color: #9294ad;
    }
    .footer .footer_copyright {
      text-align: center;
      padding-top: 18px;
      flex-direction: column;
    }
    .footer .footer_copyright .copyright {
      color: #9294ad;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
    }
    .footer .footer_copyright .footer_policy {
      color: #9294ad;
      line-height: 30px;
      letter-spacing: 0.5px;
    }
    .footer .footer_copyright .footer_policy span {
      // padding-left: 23px;
      padding: 0px 23px;
      line-height: 30px;
      // cursor: pointer;
      // &:hover{
      //   opacity: 0.5;
      // }
    }
    .footer .footer_copyright .footer_policy span:first-child {
      padding: 0px 23px;
    }
  }
</style>
