<template>
  <div class="sustainability">
    <div class="banner">
      <div class="banner_box">
        <h3>In K8</h3>
        <h3>We Play Fair</h3>
      </div>
      <div class="responsible_box">
        <h3>Responsible</h3>
        <h3>Gaming</h3>
      </div>
    </div>
    <section class="section_one">
      <p>
        We know that gaming is not completely risk-free, offering online gaming comes with the responsibility to ensure
        users have a safe, secure and fun experience. K8 has a dedicated team devoted to handling the day-to-day
        responsible gaming issues throughout the company.
      </p>
    </section>
    <section class="section_gaming">
      <div class="gaming_img">
        <img class="pc" src="@/assets/images/sustainability/Responsible_Gaming.jpg" alt="" />
        <img class="h5" src="@/assets/images/sustainability/Responsible_Gaming_h5.jpg" alt="" />
      </div>
      <div class="gaming_content">
        <div class="responsible_box">
          <h3>Responsible</h3>
          <h3>Gaming</h3>
        </div>
        <p>
          We make sure to respect and protect our users who live their passion for games and sports through our
          entertaining platforms. We encourage them to play in moderation in a safe and responsible gambling environment
          and to look out for signs of potentially harmful gambling behaviour.
        </p>
        <p>
          Since our philosophy has focused on the pleasure of playing for over 15 years, we are committed to alerting
          our customers to the potential dangers of gambling.
        </p>
      </div>
    </section>
    <section class="section_three">
      <h3>Gambling Safeguards</h3>
      <div class="three_content">
        <p>
          Tailored Closures - if a customer wishes to close an account for a specific time frame our support will be
          able to do so. The account will only be opened once the requested time frame ends. Our Safeguard tools
          include:
        </p>
      </div>
      <div class="three_container">
        <div class="card_content">
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/self_exlusion.png" alt="" />
              </div>
              <div class="title">Self-Exlusion</div>
            </div>
            <p>
              We offer a variety of cool off options, ranging from 24 hours to 6 months of self-exclusion that the
              customer can self-activate. Under no circumstances can an excluded account be reopened before due time.
            </p>
          </div>
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/reality_check.png" alt="" />
              </div>
              <div class="title">Reality Check</div>
            </div>
            <p>
              The reality check is offered to help customers keep track of how long they have been playing, it will stop
              the game until the client selects to either continue playing or exit the current game.
            </p>
          </div>
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/deposit_limits.png" alt="" />
              </div>
              <div class="title">Deposit Limits</div>
            </div>
            <p>The deposit limit protects customers from spending beyond their set limit.</p>
          </div>
        </div>
        <div class="card_content">
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/Prevention.png" alt="" />
              </div>
              <div class="title">Prevention</div>
            </div>
            <p>Inbuilt responsible gaming prevention tool by our own Data Science team.</p>
          </div>
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/Sister Sites.png" alt="" />
              </div>
              <div class="title">Sister Sites</div>
            </div>
            <p>
              If a customer states that he/she has gambling issues, the account will be immediately blocked and any
              other account the user may have on our sister sites will also be blocked.
            </p>
          </div>
          <div class="card">
            <div class="card_title">
              <div class="card_img">
                <img src="@/assets/images/sustainability/Inactivity.png" alt="" />
              </div>
              <div class="title">Inactivity</div>
            </div>
            <p>
              A message will notify clients that they have been inactive for a specific amount of time and will log them
              out from the account. If customers want to proceed to play, they will have to log in again.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section_four">
      <img class="pc" src="@/assets/images/sustainability/Social_Responsibility.jpg" alt="" />
      <img class="h5" src="@/assets/images/sustainability/Social_Responsibility_h5.jpg" alt="" />

      <div class="content">
        <div class="title">
          <h3>Social</h3>
          <h3>Responsibility</h3>
        </div>
        <div class="info">
          <p>
            Based in 7 Asian countries, K8 has offices in Manila, Dubai, Singapore, Ho Chi Minh, Mumbai, Osaka and
            Taipei, and aims to become a key player in each local ecosystem by striving to make a positive impact. With
            the launch of a new 2021-2024 strategic plan, K8 is redefining its various corporate, societal and
            environmental policies as part of its various long-term commitments.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'sustainabilityPage',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
* {
  // outline: 1px solid #ccc;
}
.sustainability {
  img {
    width: 100%;
  }
  .banner {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: url('~@/assets/images/sustainability/banner.jpg') no-repeat center / 100% 100%;
    position: relative;
    .banner_box {
      width: 560px;
      height: 302px;
      // width: 28%;
      // height: 32%;
      background-color: #6315bfc2;
      position: absolute;
      top: 7%;
      padding: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: right;
      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 50px;
        line-height: 75px;
      }
    }
    .responsible_box {
      display: none;
    }
  }
  .section_one {
    p {
      text-align: center;
      // padding: 86px 400px 209px 400px;

      margin: 4% 23.4% 12%;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }
  .section_gaming {
    display: flex;
    .gaming_img {
      padding-top: 10%;
      width: 50%;
    }
    .gaming_content {
      width: 50%;
      .responsible_box {
        // color: #6300bf;
        background-color: #6300bf;
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5%;
        margin-bottom: 10%;

        h3 {
          color: #fff;
          font-weight: 700;
          font-size: 50px;
          line-height: 75px;
          margin-left: 30px;
        }
      }
    }
    p {
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      color: #1c1f28;
      width: 66%;
      margin-left: 5%;
      margin-bottom: 5%;
      letter-spacing: 0.5px;
    }
  }
  .section_three {
    margin-bottom: 334px;
    h3 {
      color: #1c1f28;
      font-size: 40px;
      font-weight: 700;
      line-height: 67px;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 217px;
    }
    .three_content {
      width: 1100px;
      margin: auto;
      text-align: center;
      p {
        padding: 0px 239px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 116px;
      }
    }
    .three_container {
      max-width: 1374px;
      min-width: 1200px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      .card_content {
        display: flex;
        justify-content: space-between;
        gap: 4%;
      }
      .card {
        width: 30%;
        width: 30%;
        padding: 7% 5%;
        border-radius: 20px;
        border-radius: 20px;
        box-shadow: 2px 25px 12px #81818112;
        .card_img {
          width: 100px;
        }
        .title {
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
          color: #1c1f28;
          margin-bottom: 20px;
        }
        p {
          color: #77797f;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
  .section_four {
    position: relative;

    .content {
      max-width: 1374px;
      width: 100%;
      margin: auto;
      position: absolute;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
      .title {
        background-color: #6300bf;
        width: 483px;
        height: 323px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          font-weight: 700;
          font-size: 50px;
          line-height: 75px;
          color: #fff;
          padding-left: 5%;
        }
      }
      .info {
        margin-top: 33px;
        width: 49%;
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #fff;
        }
      }
    }
  }
}
// H5版
@media screen and (max-width: 768px) {
  .sustainability {
    .banner {
      width: 100%;
      height: 0;
      padding-bottom: 162%;
      background: url('~@/assets/images/sustainability/banner_h5.jpg') no-repeat center / 100% 100%;
      position: relative;
      .banner_box {
        width: 61%;
        height: 26%;
        padding: 19px;
        background-color: #6300bf;
        h3 {
          font-size: 25px;
          line-height: 37px;
        }
      }
      .responsible_box {
        background-color: #6300bf;
        // width: 180px;
        // height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0%;
        margin-bottom: 0%;
        position: absolute;
        bottom: -20%;
        right: 0%;
        width: 48%;
        // height: 130px;
        height: 21.5%;
        h3 {
          color: #fff;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-left: 24px;
        }
      }
    }
    .section_one {
      p {
        text-align: center;
        margin: 46% 5.4% 10%;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .section_gaming {
      display: flex;
      flex-direction: column;
      .gaming_content {
        width: 50%;
        .responsible_box {
          display: none;
        }
      }
      .gaming_img {
        padding-top: 0%;
        width: 100%;
      }
      .gaming_content {
        width: 100%;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        width: 100%;
        padding: 5%;
        letter-spacing: 0.5px;
        box-sizing: border-box;
        margin: 0%;
        text-align: center;
      }
    }
    .section_three {
      margin-bottom: 174px;
      h3 {
        color: #1c1f28;
        font-size: 20px;
        font-weight: 700;
        line-height: 21px;
        margin-top: 36px;
      }
      .three_content {
        display: none;
      }
      .three_container {
        max-width: initial;
        min-width: initial;
        .card_content {
          flex-direction: column;
          gap: 0%;
          margin: 5%;
        }
        .card {
          width: 100%;
          padding: 16px 32px;
          margin-bottom: 30px;
          box-sizing: border-box;
          .card_img {
            width: 60px;
          }
          .card_title {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            margin-left: 6px;
          }
          .title {
            font-size: 14px;
            line-height: 30px;
            margin-bottom: 0px;
          }
          p {
            color: #77797f;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .section_four {
      position: relative;

      .content {
        max-width: 1374px;
        width: 100%;
        margin: auto;
        position: absolute;
        top: -70px;
        left: 0%;
        transform: translateX(0%);
        .title {
          background-color: #6300bf;
          width: 203px;
          height: 127px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #fff;
            padding-left: 7%;
          }
        }
        .info {
          margin-top: 33px;
          width: 100%;
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #fff;
            padding: 0px 26px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
</style>
