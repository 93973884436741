import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Join from "@/views/Join.vue";
import News from "@/views/News.vue";
import Sustainability from "@/views/Sustainability.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

Vue.use(VueRouter);

const routes = [
  //將未經定義的路徑導到指定頁面
  {
    path: "*",
    redirect: "/",
  },
  // 首页
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      header: MainHeader,
      footer: MainFooter,
    },
  },
  {
    path: "/About",
    name: "About",
    components: {
      default: About,
      header: MainHeader,
      footer: MainFooter,
    },
  },
  {
    path: "/Join",
    name: "Join",
    components: {
      default: Join,
      header: MainHeader,
      footer: MainFooter,
    },
  },
  {
    path: "/News",
    name: "News",
    components: {
      default: News,
      header: MainHeader,
      footer: MainFooter,
    },
  },
  {
    path: "/Sustainability",
    name: "Sustainability",
    components: {
      default: Sustainability,
      header: MainHeader,
      footer: MainFooter,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
