<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <img class="banner_pc" src="@/assets/images/home/banner_bg.jpg" />
      <img class="banner_h5" src="@/assets/images/home/banner_img_h5.jpg" />
      <div class="banner_box">
        <div class="banner_info">
          <h3>Welcome to</h3>
          <h3>K8 Group</h3>
          <p>
            At The Forefront of Global Online gaming <br />
            and Crypto Betting
          </p>
        </div>
        <div class="banner_content">
          <div class="banner_img_box_line_one">
            <img class="banner_img" src="@/assets/images/home/banner1.jpg" />
            <img class="banner_img" src="@/assets/images/home/banner2.jpg" />
          </div>
          <div class="banner_img_box_line_two">
            <img class="banner_img" src="@/assets/images/home/banner3.jpg" />
            <img class="banner_img" src="@/assets/images/home/banner4.jpg" />
          </div>
        </div>
      </div>
    </div>
    <!-- info -->
    <div class="info">
      <p>We Are The Global Online</p>
      <p class="linear_word">Gambling, Gaming And Crypto</p>
      <p>Betting Provider For Over</p>
      <p>8 Million Users Worldwide!</p>
      <img src="@/assets/images/home/learnMore.png" alt="" />
    </div>
    <!-- brands -->
    <div class="brands">
      <h1>Our Brands</h1>
      <p>
        K8 Group Offers Over 8 Million Customers And Cryppto Users An Unique Gambling Experience Where They Can Enjoy
        The Thrill Of Betting On A Wide Range Of Games Both Online And On Mobile Apps.
      </p>
      <div class="brands_box">
        <div class="brands_img_one">
          <img src="@/assets/images/home/Established.jpg" />
          <div class="txt_box">
            <div class="num">
              2015
            </div>
            <div class="tip">
              Established
            </div>
          </div>
        </div>
        <div class="brands_img_two">
          <img src="@/assets/images/home/Employees.jpg" />
          <div class="txt_box">
            <div class="num">
              500+
            </div>
            <div class="tip">
              Employees
            </div>
          </div>
        </div>
        <div class="brands_img_three">
          <img src="@/assets/images/home/Nationalities.jpg" />
          <div class="txt_box">
            <div class="num">
              006
            </div>
            <div class="tip">
              Nationalities
            </div>
          </div>
        </div>
        <div class="brands_img_four">
          <img src="@/assets/images/home/Products.jpg" />
          <div class="txt_box">
            <div class="num">
              10+
            </div>
            <div class="tip">
              Products
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sustainability -->
    <div class="container sustainability">
      <div class="sustainability_title">
        <span>SUSTAINABILITY</span>
      </div>
      <div class="sustainability_box">
        <div class="sustainability_img">
          <img class="sustainability_img_pc" src="@/assets/images/home/sustainability.jpg" />
          <img class="sustainability_img_p5" src="@/assets/images/home/sustainability_h5.jpg" />
        </div>
        <div class="sustainability_content">
          <p>
            Playing our part in sustainable gaming. We operate some of the world's most innovative, diverse and
            distinctive brands, and do so in a way which is responsible and in tune with our users' needs.
          </p>
          <div class="circle">
            <span>Explore More</span>
            <img src="@/assets/images/home/feather-arrow-up-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainHome',
    components: {},
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .container {
    max-width: 1374px;
    min-width: 1200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .banner {
    // background-image: url('@/assets/images/home/banner_bg.jpg');
    // background-position: center top;
    // background-size: cover;
    // background-repeat: no-repeat;
    position: relative;
    img {
      width: 100%;
    }
    .banner_pc {
      display: block;
    }
    .banner_h5 {
      display: none;
    }
    .banner_info {
      // width: 20%;
      /* top: 261px; */
      top: 25%;
      /* left: 279px; */
      left: 15%;
      position: absolute;
      text-align: left;
      h3 {
        font-size: 50px;
        // font-size: 2.5vw;
        font-weight: 700;
        color: #fff;
        line-height: 62px;
      }
      p {
        font-size: 24px;
        // font-size: 1.2vw;
        color: #fff;
        line-height: 58px;
      }
    }
    .banner_content {
      left: 9%;
      top: 5%;
      position: absolute;
    }
    .banner_img_box_line_one {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 2%;
      .banner_img {
        width: 20%;
        border-radius: 32px;
      }
    }
    .banner_img_box_line_two {
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      margin-right: 15%;
      padding-top: 27px;
      gap: 2%;
    }
    .banner_img_box_line_two .banner_img {
      border-radius: 32px;
      width: 31%;
      &:first-child {
        border-radius: 32px;
        width: 20%;
      }
    }
  }

  .info {
    background-image: url('@/assets/images/home/info_bg.jpg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 783px;
    padding-top: 120px;
    text-align: center;
    p {
      font-size: 40px;
      line-height: 88px;
      color: #fff;
      font-weight: 400;
    }
    .linear_word {
      color: transparent;
      background-image: linear-gradient(270deg, #f31299, #6300bf);
      -webkit-background-clip: text;
      background-clip: text;
      font-weight: 800;
    }
    img {
      margin-top: 153px;
    }
  }
  .brands {
    max-width: 1374px;
    width: 100%;

    text-align: center;
    padding: 220px 0px;
    margin-right: auto;
    margin-left: auto;
  }
  .brands h1 {
    color: #1c1f28;
    font-weight: 700;
    font-size: 40px;
    line-height: 67px;
  }
  .brands p {
    width: 701px;
    color: #1c1f28;
    text-align: center;
    line-height: 27px;
    display: inline-block;
  }
  .brands_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .txt_box {
      position: absolute;
      text-align: left;
      bottom: 28px;
      left: 23px;
      color: #fff;
      .num {
        font-size: 90px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .tip {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  .brands_box img {
    width: 100%;
  }
  .brands_box .brands_img_one {
    position: relative;
    width: 21%;
    padding-top: 111px;
    padding-top: 9%;
  }
  .brands_box .brands_img_two {
    position: relative;
    width: 21%;
    padding-top: 222px;
    padding-top: 16%;
  }
  .brands_box .brands_img_three {
    position: relative;
    width: 21%;
    padding-top: 333px;
    padding-top: 23%;
  }
  .brands_box .brands_img_four {
    position: relative;
    width: 21%;
    padding-top: 444px;
    padding-top: 30%;
  }
  .sustainability {
    // width: 1374px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 148px;
  }
  .sustainability .sustainability_title {
    font-size: 40px;
    color: #1c1f28;
    line-height: 67px;
    padding-bottom: 66px;
    font-weight: 700;
  }
  .sustainability_box {
    display: flex;
    justify-content: space-between;
  }
  .sustainability_img img {
    width: 743px;
  }
  .sustainability_img {
    img {
      width: 743px;
    }
    .sustainability_img_pc {
      display: block;
    }
    .sustainability_img_p5 {
      display: none;
    }
  }
  .sustainability_content {
    width: 519px;
  }
  .sustainability_content p {
    font-size: 18px;
    color: #1c1f28;
    line-height: 27px;
    font-weight: 400;
  }
  .sustainability_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .sustainability .sustainability_content .circle {
    width: 297px;
    height: 279px;
    border: 4px solid #6300bf;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 197px;
  }

  .sustainability .sustainability_content .circle span {
    font-size: 24px;
    font-weight: 400;
    color: #6300bf;
  }

  @media screen and (max-width: 768px) {
    .container {
      min-width: 100%;
      box-sizing: border-box;
    }
    .banner {
      .banner_pc {
        display: none;
      }
      .banner_h5 {
        display: block;
      }
      .banner_content {
        right: 7%;
        top: auto;
        bottom: 5%;
        position: absolute;
      }
      .banner_info {
        left: 50%;
        transform: translateX(-50%);
        top: 22%;
        width: 100%;
        text-align: center;
        h3 {
          font-size: 25px;
          font-weight: 700;
          line-height: 37px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          padding-top: 10px;
        }
      }
      .banner_img_box_line_one {
        .banner_img {
          border-radius: 6%;
          width: 35%;
        }
      }
      .banner_img_box_line_two {
        .banner_img {
          border-radius: 6%;
          width: 63%;
          &:first-child {
            border-radius: 6%;
            width: 40%;
          }
        }
      }
    }
    .info {
      height: 458px;
    }
    .info p {
      font-size: 20px;
      line-height: 48px;
    }
    .brands {
      padding: 10% 0px;
    }
    .brands h1 {
      font-size: 20px;
      line-height: 67px;
    }
    .brands p {
      display: none;
    }
    .brands_box {
      justify-content: center;
      flex-wrap: wrap;
      gap: 2%;
      .txt_box {
        position: absolute;
        text-align: left;
        bottom: 15px;
        left: 13px;
        color: #fff;
        .num {
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 0px;
        }
        .tip {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .brands_box img {
      width: 100%;
    }
    .brands_box .brands_img_one {
      width: 28%;
      padding-top: 0px;
      // padding-left: 10%;
      margin-left: 10%;
      padding-left: 0%;
    }
    .brands_box .brands_img_two {
      width: 28%;
      padding-top: 0px;
      padding-right: 24%;
    }
    .brands_box .brands_img_three {
      width: 28%;
      padding-top: 0px;
      margin-left: 24%;
      // padding-left: 24%;
      padding-left: 0%;
      margin-top: 3%;
    }
    .brands_box .brands_img_four {
      width: 28%;
      padding-top: 0px;
      padding-right: 10%;
      margin-top: 3%;
    }
    .sustainability_box {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
    .sustainability_content {
      width: 100%;
      p {
        padding: 16px;
        text-align: center;
      }
    }
    .sustainability_img {
      img {
        width: 100%;
      }
      .sustainability_img_pc {
        display: none;
      }
      .sustainability_img_p5 {
        display: block;
      }
    }
    .sustainability {
      padding-bottom: 70px;
      .sustainability_title {
        font-size: 20px;
        padding-bottom: 32px;
        text-align: center;
      }
    }
    .sustainability .sustainability_content .circle {
      width: 124px;
      height: 124px;
      border-radius: 50%;
      margin-top: 32px;
      span {
        font-size: 12px;
        margin-right: 5px;
      }
      img {
        width: 9px;
        transform: rotate(45deg);
      }
    }
  }
</style>
