<template>
  <div class="MainHeader">

    <div class="pc">
      <img class="img_logo" src="@/assets/images/common/logo.png" @click="toPage('Home')" alt="">
      <nav class="menu">
        <div class="menu_btn" :class="{ active: currentPage === 'Home' }" @click="toPage('Home')">Home</div>
        <div class="menu_btn" :class="{ active: currentPage === 'About' }" @click="toPage('About')">About Us</div>
        <div class="menu_btn" :class="{ active: currentPage === 'Sustainability' }" @click="toPage('Sustainability')">
          Sustainability</div>
        <div class="menu_btn" :class="{ active: currentPage === 'Join' }" @click="toPage('Join')">Join Us</div>
      </nav>
    </div>
    <div class="h5">
      <div class="img_menu" :class="{ close: showMenuH5 }" @click="toggleMenu()"></div>
      <img class="img_logo" src="@/assets/images/common/logo.png" alt="">
      <div></div>
      <div v-if="showMenuH5" class="menu">
        <div class="menu_btn" :class="{ active: currentPage === 'Home' }" @click="toPage('Home')">Home</div>
        <div class="menu_btn" :class="{ active: currentPage === 'About' }" @click="toPage('About')">About Us</div>
        <div class="menu_btn" :class="{ active: currentPage === 'Sustainability' }" @click="toPage('Sustainability')">
          Sustainability</div>
        <div class="menu_btn" :class="{ active: currentPage === 'Join' }" @click="toPage('Join')">Join Us</div>
      </div>
    </div>


    <!-- <transition>
      <nav :class="{ 'h5_header': isOpenNav }" class="header" v-if="isOpenNav">
        <router-link to="/">Home</router-link> |
        <router-link to="/About">About Us</router-link> |
        <router-link to="/News">News</router-link> |
        <router-link to="/Sustainability">sustainability</router-link> |
        <router-link to="/Join">join us</router-link> |
      </nav>
    </transition> -->
  </div>
</template>
<script>
export default {
  name: "MainHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      showMenuH5: false,
    }
  },
  computed: {
    currentPage() {
      return this.$route.name
    }
  },
  methods: {
    toggleMenu() {
      this.showMenuH5 = !this.showMenuH5;
    },
    toPage(url) {
      this.$router.push(url);
      this.showMenuH5 = false;
    }
  }
};
</script>

<style scoped lang="scss">
// * {
//   outline: 1px solid #ccc;
// }

.MainHeader {
  .pc {
    min-width: 1200px;
    max-width: 1374px;
    width: 100%;
    height: 112px;
    margin: auto;
    align-items: center;
    display: none;

    .img_logo {
      width: 89px;
      margin-right: 20px;
      cursor: pointer;
    }

    .menu {
      .menu_btn {
        display: inline-block;
        font-size: 14px;
        color: #77797F;
        margin: 10px;
        text-decoration: none;
        font-weight: 500;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
          // font-weight: 700;
          color: #1C1F28;
        }

        &.active {
          font-weight: 700;
          color: #1C1F28;
        }
      }
    }
  }

  .h5 {
    width: 100%;
    height: 61px;
    // padding: 14px 20px;
    // display: block;
    // justify-content: space-between;
    // align-items: center;
    box-sizing: border-box;
    position: relative;

    .img_menu {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 20px;
      width: 16px;
      height: 16px;
      background: url('~@/assets/images/header/icon_menu.png') no-repeat center / contain;

      &.close {
        background: url('~@/assets/images/header/icon_close.png') no-repeat center / contain;
      }
    }

    .img_logo {
      width: 49px;
      height: fit-content;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .menu {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 61px;
      left: 0;
      z-index: 1;
      background-color: #fff;
      box-sizing: border-box;
      padding: 45px;

      .menu_btn {
        font-size: 20px;
        font-weight: 400;
        color: #77797F;
        width: 100%;
        padding: 32px 0;
        border-bottom: 1px solid #77797F;
        text-align: left;
        display: block;

        &.active {
          color: #1C1F28;
        }
      }
    }
  }

  // PC版 768px以上
  @media screen and (min-width: 768px) {
    .pc {
      display: flex;
    }

    .h5 {
      display: none;
    }
  }


}
</style>
