<template>
  <div>
    News
  </div>
</template>
  
<script>
export default {
  name: "newsPage",
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() { },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
