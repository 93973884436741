<template>
  <div class="join_page">
    <div class="banner">
      <img class="pc" src="@/assets/images/join/banner.jpg" alt="" />
      <img class="h5" src="@/assets/images/join/banner_h5.jpg" alt="" />
      <div class="txt_box">
        <h4>CAREERS</h4>
        <div class="txt">Passionate about sports and gaming? Do you like challenges and working as a team?</div>
        <div class="txt_join">Join Us!</div>
      </div>
    </div>

    <section class="section_care">
      <div class="left_box">
        <img src="@/assets/images/join/care_pic1.jpg" alt="" />
      </div>
      <div class="right_box">
        <img class="care_bg" src="@/assets/images/join/care_bg.png" alt="" />
        <div class="txt">
          No ifs, no buts. We care for our users, our colleagues and the communities where we operate.
        </div>
      </div>
      <div class="txt_box">
        <div class="txt">How it works</div>
      </div>
    </section>

    <section class="section_respect">
      <div class="txt_box">
        <img class="pespect_bg" src="@/assets/images/join/pespect_bg.png" alt="" />
        <div class="txt">
          We act with respect and fairness. We do the right thing because it's the right thing to do.
        </div>
      </div>
      <div class="pic_box pc">
        <div class="pic_item item1">
          <img src="@/assets/images/join/pespect_pic1.jpg" alt="" />
        </div>
        <div class="pic_item item2">
          <img src="@/assets/images/join/pespect_pic2.jpg" alt="" />
        </div>
        <div class="pic_item item3">
          <img src="@/assets/images/join/pespect_pic3.jpg" alt="" />
        </div>
      </div>
      <div class="pic_box h5">
        <img src="@/assets/images/join/pespect_pic4.jpg" alt="" />
      </div>
    </section>

    <section class="section_together">
      <div class="pic_box">
        <img class="pc" src="@/assets/images/join/together_pic1.jpg" alt="" />
        <img class="h5" src="@/assets/images/join/together_pic1_h5.jpg" alt="" />
      </div>
      <div class="txt_box">
        <img class="bg" src="@/assets/images/join/together_bg.png" alt="" />
        <div class="txt">We collaborate. Together we are safer, better and stronger.</div>
      </div>
    </section>

    <section class="section_commit">
      <div class="txt_box">
        <img class="bg" src="@/assets/images/join/commit_bg.png" alt="" />
        <div class="txt">
          We're bold, proactive, ambitious and challenging. We don't do things by halves. We enjoy what we do.
        </div>
      </div>
      <div class="pic_box">
        <img class="pic1 pc" src="@/assets/images/join/commit_pic1.jpg" alt="" />
        <img class="pic2 pc" src="@/assets/images/join/commit_pic2.jpg" alt="" />
        <img class="pic3 h5" src="@/assets/images/join/commit_pic1_h5.jpg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'joinPage',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
// * {
//   outline: 1px solid #ccc;
// }

.join_page {
  img {
    width: 100%;
  }

  .banner {
    position: relative;
    margin-bottom: 10.5%;

    .txt_box {
      width: 19.4%;
      height: fit-content;
      padding: 56px 35px;
      line-height: 1.5;
      text-align: center;
      background-color: #6315bf;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;

      h4 {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .txt {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }

      .txt_join {
        font-size: 30px;
        font-weight: 600;
        color: #fff500;
      }
    }
  }

  .section_care {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 8.5%;

    .left_box {
      width: 41.2%;
      padding-left: 8.4%;
    }

    .right_box {
      width: 50%;
      padding: 14.9% 0 0 3.3%;

      .care_bg {
        width: 71.7%;
        margin-bottom: 2%;
      }

      .txt {
        font-size: 18px;
        font-weight: 400;
        color: #1c1f28;
        width: 73.4%;
      }
    }

    .txt_box {
      width: 31.4%;
      height: 0;
      padding-bottom: 21%;
      background-color: #6300bf;
      position: absolute;
      right: 0;
      top: -15%;
      box-sizing: border-box;
      padding-left: 10%;

      .txt {
        color: #fff;
        font-weight: 700;
        font-size: 50px;
        position: absolute;
        height: fit-content;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 10%;
      }
    }
  }

  .section_respect {
    width: 100%;
    position: relative;
    margin-bottom: 13.1%;

    .pic_box {
      display: flex;
      justify-content: space-between;
    }

    .pic_item {
      width: 31.2%;

      &.item1 {
        padding-top: 40%;
      }

      &.item2 {
        padding-top: 28%;
      }
    }

    .txt_box {
      position: absolute;
      width: 33.3%;
      left: 8%;

      .pespect_bg {
        margin-bottom: 5%;
      }

      .txt {
        font-size: 18px;
        font-weight: 400;
        color: #1c1f28;
        line-height: 1.5;
      }
    }
  }

  .section_together {
    width: 100%;
    position: relative;
    margin-bottom: 22%;
    display: flex;
    justify-content: center;

    .pic_box {
      width: 35.2%;
      padding-top: 2.3%;
      margin-left: 5%;
    }

    .txt_box {
      width: 38.4%;
      margin-left: 4.5%;

      .bg {
        margin-bottom: 3%;
      }

      .txt {
        font-size: 18px;
        font-weight: 400;
        color: #1c1f28;
      }
    }
  }

  .section_commit {
    width: 100%;
    position: relative;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    .txt_box {
      width: 50%;
      box-sizing: border-box;
      padding-left: 8.4%;

      .bg {
        width: 81.2%;
        margin-bottom: 4%;
      }

      .txt {
        font-size: 18px;
        color: #1c1f28;
        padding-right: 10%;
      }
    }

    .pic_box {
      width: 50%;
      position: relative;
      text-align: right;

      .pic1 {
        width: 81.1%;
      }

      .pic2 {
        position: absolute;
        top: -33%;
        left: -9%;
        width: 49.9%;
      }
    }
  }

  // H5版
  @media screen and (max-width: 768px) {
    .banner {
      margin-bottom: 35.2%;

      .txt_box {
        width: 34.7%;
        padding: 6.4% 4.8%;

        h4 {
          font-size: 25px;
        }

        .txt {
          font-size: 13px;
        }

        .txt_join {
          font-size: 14px;
        }
      }
    }

    .section_care {
      margin-bottom: 11.9%;

      .left_box {
        width: 49.2%;
        padding-left: initial;
      }

      .right_box {
        padding: 3.3% 0 0 3.3%;

        .care_bg {
          width: 84.8%;
        }

        .txt {
          font-size: 14px;
          color: #1c1f28;
          width: 85.6%;
        }
      }

      .txt_box {
        width: 48%;
        padding-bottom: 29%;
        top: -83%;

        .txt {
          font-size: 16px;
        }
      }
    }

    .section_respect {
      margin-bottom: 21%;

      .txt_box {
        position: relative;
        width: 89.1%;
        margin: auto;
        left: 0;
        margin-bottom: 11%;

        .pespect_bg {
          width: 45%;
        }

        .txt {
          font-size: 14px;
        }
      }
    }

    .section_together {
      margin-bottom: 8.5%;
      justify-content: space-between;

      .pic_box {
        width: 50%;
        padding-top: initial;
        margin-left: initial;
      }

      .txt_box {
        width: 53.9%;
        margin-left: initial;
        padding: 0 4%;
        box-sizing: border-box;

        .bg {
          margin-bottom: 6%;
        }

        .txt {
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }

    .section_commit {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      margin-bottom: 10%;
      letter-spacing: 1px;

      .txt_box {
        width: 89%;
        padding-left: initial;

        .bg {
          width: 44.3%;
        }

        .txt {
          font-size: 14px;
        }
      }

      .pic_box {
        width: 100%;
        margin-bottom: 8.5%;
      }
    }
  }
}
</style>
