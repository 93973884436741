import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueRouter from 'vue-router'


Vue.use(VueRouter);


Vue.config.productionTip = false;

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
